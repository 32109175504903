import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { useAuthContext } from '@app/context/AuthContext';
import { UserContext } from '@app/context/UserContext/UserContext';
import { Rewards, Subscription, User, WalletProfile } from '@app/types/user';

import { fetchCreditBalance } from './utils/fetchCreditBalance';
import { fetchRewards } from './utils/fetchRewards';
import { fetchSubscription } from './utils/fetchSubscription';
import { fetchUserProfile } from './utils/fetchUserProfile';
import { fetchWalletProfile } from './utils/fetchWalletProfile';

export const UserContextProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const { loggedIn } = useAuthContext();

  const [user, setUser] = useState<User>();
  const [walletProfile, setWalletProfile] = useState<WalletProfile>();

  const [balance, setBalance] = useState('0');
  const [rewards, setRewards] = useState<Rewards>();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>();

  useEffect(() => {
    if (loggedIn) {
      fetchRewards(setRewards);
      fetchUserProfile(setUser);
      fetchCreditBalance(setBalance);
      fetchSubscription(setSubscriptions);
      fetchWalletProfile(setWalletProfile);
    }
  }, [loggedIn]);

  const refetchWalletProfile = useCallback(async () => {
    fetchWalletProfile(setWalletProfile);
  }, []);

  const refetchCreditBalance = useCallback(async () => {
    fetchCreditBalance(setBalance);
  }, []);

  const refetchRewards = useCallback(async () => {
    fetchRewards(setRewards);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        walletProfile,
        setWalletProfile,
        rewards,
        balance,
        subscriptions,
        refetchRewards,
        refetchCreditBalance,
        refetchWalletProfile,
        username: walletProfile?.displayName || user?.username
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
