import { toast } from 'react-toastify';

export interface Result<T> {
  data?: T;
  success: boolean;
}

interface RequestOpts {
  error?: string;
  skipToast?: boolean;
}

// trigger

export const fetchWrapper = async <T>(
  input: RequestInfo | URL,
  init?: RequestInit,
  opts?: RequestOpts
): Promise<Result<T>> => {
  const { error, skipToast } = opts || {};

  const response = await fetch(input, init);

  const result = await response.json();

  if (response.ok) {
    return { data: result, success: true };
  }

  if (!skipToast && response.status !== 401) {
    toast(
      error || result?.error?.message || 'Error occurred. Try again later.',
      {
        type: 'error'
      }
    );
  }

  return {
    success: false
  };
};
